<template>
  <div class="join">
    <img src="@/assets/img/img8.jpg" />
    <ContentTop></ContentTop>
    <div class="a_content">
      <div class="ac_title">加盟咨询</div>
      <div class="ac_content">我们在此郑重承诺：我们将尊重你的隐私为您保密!</div>
      <div class="ac_box">
        <div class="acb_Box">
          <div class="nick_left">姓名</div>
          <input
            class="acb_input"
            v-model="name"
            placeholder="先生/女士"
          />
        </div>
        <div class="acb_Box">
          <div class="nick_left">手机</div>
          <input
            class="acb_input"
            v-model="phone"
            placeholder="请输入您的手机号"
          />
        </div>
        <div class="acb_Box">
          <div class="nick_left">区域</div>
          <input
            class="acb_input"
            v-model="address"
            placeholder="请输入您所在城市"
          />
        </div>
        <!-- <div class="acb_Box">
          <div class="nick_left">项目</div>
          <input
            class="acb_input"
            v-model="project"
            placeholder="请输入加盟项目"
          />
        </div> -->
        
        <div class="acb_Box">
          <div class="nick_left">快捷留言</div>
          <div class="nick_right">
            <div
              class="nr_title"
              :class="item.show?'show_title':''"
              v-for="(item,index) in messList"
              :key="index"
              @click="getClick(item,index)"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="acb_Box" style="margin-top:-36rem">
          <div class="nick_left">留言</div>
          <textarea
            class="acb_text"
            v-model="message"
            placeholder="请输入留言内容"
          />
        </div>
      </div>

      <div class="ac_btn">
        <button
          class="acb_btn acb_btn_left"
          @click="getTijiao"
        >提交留言</button>
        <button
          class="acb_btn acb_btn_right"
          @click="getClear"
        >重新留言</button>
      </div>
    </div>
    <Bottom @getTop="getTop"></Bottom>

  </div>
</template>

<script>
import Bottom from "@/components/bottom";
import ContentTop from "../../components/content_top.vue";

export default {
  name: "joinIndex",
  components: {
    ContentTop,
    Bottom
  },
  data() {
    return {
      name: "",
      phone: "",
      address: "",
      project: "",
      message: "",

      messList: [
        {
          name: "01.   开店对城市的要求？供应链支持到几级城市呢？？",
          show: false
        },
        { name: "02.   开一家店需要投入多少资金？", show: false },
        { name: "03.   最快回本周期是多久？", show: false },
        { name: "04.   项目店型有那些？", show: false },
        { name: "05.   运营支持有那些？", show: false },
        { name: "06.   从0到1开一个新店最快要多久？", show: false },
        { name: "07.   未来规划每个城市开几家店？", show: false }
      ]
    };
  },

  mounted() {},

  methods: {
    getTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // 平滑滚动
      });
    },
    getTijiao() {
      if (
        this.name &&
        this.phone &&
        this.address &&
        this.project &&
        this.message
      ) {
        this.$message({
          message: "提交成功",
          type: "success"
        });
      } else {
        this.$message.error("请全部填写完成");
      }
    },
    getClick(item, index) {
      if (!this.messList[index].show) {
        this.messList[index].show = true;
        this.message = this.message + item.name;
      }
    },
    getClear() {
      this.name = "";
      this.phone = "";
      this.address = "";
      this.project = "";
      this.message = "";
      for (var i = 0; i < this.messList.length; i++) {
        this.messList[i].show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 1550px) {
  .i_bottom {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
  .a_content{
    width: 100vw !important;
    padding: 0 10rem;
  }
  .ac_box{
    display: block !important;
  }
  .nr_title{
    width: 100rem !important;
    height: 15rem !important;
    line-height: 15rem !important;
    white-space: nowrap;
  }
  .acb_btn{
    width: 40rem !important;
    height: 13rem !important;
  }

  .acb_input{
    height: 10rem !important;
  }

  .acb_Box{
    align-items: center;
  }

  //选择最后一个元素
  .acb_Box:nth-last-child(1){
    margin-top: 0 !important;
  }
  .acb_text{
    width: 100rem !important;
  }
}


.join {
  img {
    width: 100%;
    height: 50rem;
  }
  .a_content {
    margin: 0 auto 2.5rem;
    width: 120rem;
    text-align: center;
    .ac_title {
      font-size: 3.6rem;
      font-weight: 400;
      color: #000000;
      margin: 5rem 0 2.9rem;
    }
    .ac_content {
      font-size: 1.6rem;
      font-weight: 400;
      color: #fe374c;
      margin-bottom: 5rem;
    }
    .ac_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .acb_Box {
        display: flex;
        margin-bottom: 30px;
        .nick_left {
          font-size: 1.6rem;
          height: 5rem;
          line-height: 5rem;
          font-weight: 400;
          color: #666666;
          margin-right: 2rem;
        }
        .nick_right {
          .nr_title {
            width: 50rem;
            height: 5rem;
            text-align: left;
            background: #ffffff;
            border: 0.1rem solid #f1f1f1;
            padding-left: 3rem;
            line-height: 5rem;
            font-size: 1.6rem;
            font-weight: 400;
            color: #666666;
            margin-bottom: 1rem;
          }
          .show_title {
            background: #e30920;
            color: #fff;
          }
          .nr_title:last-child {
            margin-bottom: 0;
          }
        }

        .acb_input {
          padding-left: 3rem;
          border: none;
          width: 50rem;
          height: 5rem;
          background: #f5f5f5;
          font-size: 1.6rem;
        }
        .acb_text {
          border: none;
          width: 50rem;
          height: 32.5rem;
          background: #f5f5f5;
          padding: 2rem 3rem;
          font-size: 1.6rem;
        }
      }
    }
    .ac_btn {
      margin: 5rem auto 0;
      display: flex;
      justify-content: center;
      .acb_btn {
        width: 20rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 400;
        border: none;
      }
      .acb_btn_left {
        background: #e30920;
        color: #ffffff;
        margin-right: 100px;
      }
      .acb_btn_right {
        border: 0.1rem solid #e11731;
        background: #ffffff;
        color: #e30920;
      }
    }
  }
}
</style>